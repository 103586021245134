import React, { useState, useEffect, useCallback, useMemo } from 'react';

import { Container, Content } from 'styles/sgo_wrappers';
import { useHistory, useLocation } from 'react-router-dom';

import SGOHeader from 'components/SGOHeader';
import SGONavbar from 'components/SGONavbar';
import ScrollTop from 'components/ScrollTop';
import SGOFooter from 'components/SGOFooter';
import Loading from 'components/Loading';
import IncludeButton from 'components/IncludeButton';

import Checkbox from 'components/Checkbox';

import api from 'services/api';

import { useCredentials } from 'hooks/credentials';
import { useAuth } from 'hooks/auth';
import { useToast } from 'hooks/toast';

import * as S from 'styles/dialog_consult';
import * as O from 'styles/option_buttons';
import * as D from 'styles/dialog_delete';
import { ShowProps } from 'styles/dialog_consult';
import { DeleteProps, ModalDeleteContent } from 'styles/dialog_delete';
import { FaFileExcel, FaPencilAlt, FaSearch, FaTimes } from 'react-icons/fa';
import { addDays, format, isAfter } from 'date-fns';
import { parseISO } from 'date-fns/esm';

import { deconvertSpecialChars } from 'utils/specialChars';
import { handleTimeZone } from 'utils/formatDate';

import moment from 'moment';

import {
  GridContainer,
  GridItem,
  GridSVCItem,
  TelephoneContainer,
  InfoContainer,
  ModalShowContent,
  PrintContainer,
  InfoMessageContainer,
} from './styles';

interface StateProps {
  type: string;
  seq: string;
  year: string;
  loccod: string;
  tscod: string;
  guia1: string;
  guia2: string;
  dtini: string;
  dtfim: string;

  // for S type
  anbcod: string;
  svcaplic: string;
}

export interface OficinistasProps {
  seq: number;
  name: string;
  address: string;
  district: string;
  city: string;
  state: string;
  cep: string;
  fres: string;
  fcel: string;
  dtnasc: string;
  mail: string;
  loccod: string;
  sessmed: boolean;
  pers: boolean;
  oseq: number;
  ano: string;
  dtcad: string;
  tscod: string;

  // different for S type
  egseq: string;
  sgtipo: string;
  anbcod: string;
  anbdesc: string;
  locdesc: string;
  zoncod: string;
  zondesc: string;
}

const AtividadesParticipantes: React.FC = () => {
  const { errorHandling } = useCredentials();
  const { pathname } = useLocation();
  const location = useLocation<StateProps>();
  const history = useHistory();
  const [list, setList] = useState<OficinistasProps[]>([]);
  const { user } = useAuth();
  const { addToast } = useToast();
  const [show, setShow] = useState<ShowProps>({
    title: '',
    open: false,
    content: '',
  });

  const [sPrint, setSPrint] = useState<ShowProps>({} as ShowProps);

  const [cadastrados, setCadastrados] = useState<string[]>([]);
  const [deleteDiag, setDeleteDiag] = useState<DeleteProps>({
    open: false,
    content: '',
    values: {},
  });

  const [loading, setLoading] = useState(true);

  const [maxDate, setMaxDate] = useState(() => {
    return new Date();
  });

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [at, setAt] = useState<StateProps>(() => {
    if (!location.state) {
      return {} as StateProps;
    }
    const {
      type,
      seq,
      year,
      loccod,
      tscod,
      guia1,
      guia2,
      dtini,
      dtfim,
      // for S TYPE
      anbcod,
      svcaplic,
    } = location.state;
    return {
      type,
      seq,
      year,
      loccod,
      tscod,
      guia1,
      guia2,
      dtini,
      dtfim,
      anbcod,
      svcaplic,
    };
  });

  const [able, setAble] = useState(false);

  const getList = useCallback(async () => {
    const send = {
      type: at.type,
      seq: at.seq,
      iniDate: at.dtini,
    };

    try {
      const response = await api.get(
        `/sgo/atividades_participantes_list.php?data=${JSON.stringify(send)}`,
      );
      const { previous, dateReturned } = response.data;
      setList(previous);
      setMaxDate(handleTimeZone(dateReturned));
      if (at.type === 'S') {
        setCadastrados(previous.map((item: OficinistasProps) => item.egseq));
      }

      setLoading(false);
    } catch (err) {
      setLoading(false);
      errorHandling(err);
    }
  }, [at, errorHandling]);

  useEffect(() => {
    if (
      !(
        location.pathname.includes('atividades') ||
        location.pathname.includes('servicos')
      )
    ) {
      history.replace(
        `${location.pathname.substring(
          0,
          location.pathname.indexOf('secretaria') + 10,
        )}/not-found`,
      );
    }
    if (!location.state) {
      history.replace(location.pathname.replace('/participantes', ''));
      return;
    }

    const {
      year,
      loccod,
      tscod,
      guia1,
      guia2,
      anbcod,
      svcaplic,
    } = location.state;

    if (at.type === 'O') {
      if (
        handleTimeZone(`${year}-01-01`).getFullYear() >=
          handleTimeZone(new Date()).getFullYear() &&
        ((user.perfil === 'LOC' && loccod === user.loccod) ||
          user.gseq === guia1 ||
          user.gseq === guia2 ||
          (['SA', 'SJ'].indexOf(tscod) > -1 && loccod === user.loccod))
      ) {
        setAble(true);
      }
    } else if (
      parseInt(year, 10) >=
        new Date(parseISO(new Date().toISOString())).getFullYear() &&
      ((['ZON', 'INT'].indexOf(user.perfil) > -1 && tscod === 'AI') ||
        (user.perfil === 'NAC' &&
          ['AN', 'JF'].indexOf(tscod) > -1 &&
          anbcod === user.anbc) ||
        (user.perfil === 'LOC' &&
          [
            'CG',
            'CO',
            'ED',
            'GC',
            'JA',
            'RC',
            'RE',
            'CC',
            'CB',
            'JE',
            'PE',
            'RD',
            'SA',
            'SJ',
            'TA',
            'TC',
            'TD',
            'TJ',
          ].indexOf(tscod) > -1 &&
          loccod === user.loccod) ||
        // eslint-disable-next-line react/jsx-indent
        (['CB', 'JE', 'PE', 'RD'].indexOf(tscod) > -1 &&
          user.gseq === svcaplic &&
          user.loccod === loccod))
    ) {
      setAble(true);
    }

    getList();
  }, [
    at.type,
    getList,
    history,
    location.pathname,
    location.state,
    user.anbc,
    user.gseq,
    user.loccod,
    user.perfil,
  ]);

  const handleCheckbox = useCallback(
    async (field: string, oseq: number) => {
      const input = document.querySelector<HTMLInputElement>(
        `input[id="${field}${oseq}"]`,
      );
      const value = input?.checked;

      try {
        const send = {
          seq: at.seq,
          status: value,
          field,
          oseq,
        };

        await api.get(
          `/sgo/atividades_participantes_checkboxes.php?data=${JSON.stringify(
            send,
          )}`,
        );

        const index = list.findIndex(
          (item: OficinistasProps) => item.oseq === oseq,
        );

        list[index] = {
          ...list[index],
          pers: field === 'P' ? !list[index].pers : list[index].pers,
          sessmed: field === 'M' ? !list[index].sessmed : list[index].sessmed,
        };

        setList((state) => [...state]);
      } catch (err) {
        errorHandling(err);
      }
    },
    [at.seq, list, errorHandling],
  );

  const handleExclusion = useCallback(
    async (values: DeleteProps['values']) => {
      if (values) {
        try {
          setLoading(true);
          setDeleteDiag({ open: false });
          await api.get(
            `/sgo/atividades_participantes_delete.php?data=${JSON.stringify(
              values,
            )}`,
          );
          setList(
            list.filter((item: OficinistasProps) =>
              at.type === 'O'
                ? item.oseq !== values.oseq
                : item.egseq !== values.oseq,
            ),
          );

          if (at.type === 'S') {
            setCadastrados(cadastrados.filter((item) => item !== values.oseq));
          }

          setLoading(false);
          addToast({
            type: 'success',
            title: 'Sucesso',
            description: `${at.type === 'O' ? 'Oficinista' : 'Guia'} ${
              values.name
            } removido desta atividade.`,
          });
        } catch (err) {
          errorHandling(err);
        }
      }
    },
    [list, at.type, addToast, cadastrados, errorHandling],
  );

  const handlePrintDiag = useCallback(() => {
    setSPrint((state) => ({ ...state, open: !state.open }));
  }, []);

  const handlePrint = useCallback(() => {
    setSPrint((state) => ({ ...state, open: !state.open }));

    const link = document.querySelector<HTMLAnchorElement>(`a[id=xls]`);

    if (link) {
      link.click();
    }
    const timer = setTimeout(() => {
      setSPrint((state) => ({ ...state, open: !state.open }));
    }, 7500);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  const allowSession = useMemo((): boolean => {
    const { dtini, tscod } = location.state;
    const init = handleTimeZone(dtini);
    const maxed = handleTimeZone(maxDate);
    const today = handleTimeZone(new Date());

    const initDated = new Date(
      init.getFullYear(),
      init.getMonth(),
      init.getDate(),
      0,
      0,
      0,
    );
    const maxedDated = new Date(
      maxed.getFullYear(),
      maxed.getMonth(),
      maxed.getDate(),
      0,
      0,
      0,
    );
    const todayDated = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate(),
      0,
      0,
      0,
    );
    const formats = 'YYYY-mm-dd';

    const initMoment = moment(
      addDays(initDated, tscod === 'TA' ? 14 : 7),
      formats,
    );
    const maxedMoment = moment(maxedDated, formats);
    const base = moment(todayDated, formats);

    return (
      able && base.isSameOrAfter(initMoment) && base.isSameOrBefore(maxedMoment)
    );
  }, [able, location.state, maxDate]);

  const allowPersistent = useMemo((): boolean => {
    const { dtfim } = location.state;
    const end = handleTimeZone(dtfim);
    const maxed = handleTimeZone(maxDate);
    const today = handleTimeZone(new Date());

    const endDated = new Date(
      end.getFullYear(),
      end.getMonth(),
      end.getDate(),
      0,
      0,
      0,
    );
    const maxedDated = new Date(
      maxed.getFullYear(),
      maxed.getMonth(),
      maxed.getDate(),
      0,
      0,
      0,
    );
    const todayDated = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate(),
      0,
      0,
      0,
    );
    const formats = 'YYYY-mm-dd';

    const endMoment = moment(endDated, formats);
    const maxedMoment = moment(maxedDated, formats);
    const base = moment(todayDated, formats);

    return (
      able && base.isSameOrAfter(endMoment) && base.isSameOrBefore(maxedMoment)
    );
  }, [able, location.state, maxDate]);
  return (
    <Container>
      <Loading isLoading={loading} />
      {able && (
        <IncludeButton
          options={{
            seq: at.seq,
            type: at.type,
            tscod: at.tscod,
            cadastrados,
          }}
        />
      )}

      <ScrollTop />
      <SGOHeader />
      <SGONavbar
        title={`${at?.type === 'O' ? 'Oficinistas' : 'Participantes'} ${
          at?.type
        }-${at?.seq}`}
        noLinks
      />
      <Content>
        {at.type === 'O' ? (
          <>
            <InfoContainer>
              <span>
                <p>
                  Reunião de Abertura:&nbsp;
                  <strong>
                    {format(new Date(parseISO(at.dtini)), 'dd/MM/yyyy')}
                  </strong>
                </p>
              </span>
              <span>
                <p>
                  Liberação Sessão de Medição:&nbsp;
                  <strong>
                    {format(
                      addDays(
                        new Date(parseISO(at.dtini)),
                        at.tscod === 'TA' ? 14 : 7,
                      ),
                      'dd/MM/yyyy',
                    )}
                  </strong>
                </p>
              </span>
              <span>
                <p>
                  Liberação Perseverantes:&nbsp;
                  <strong>
                    {format(new Date(parseISO(at.dtfim)), 'dd/MM/yyyy')}
                  </strong>
                </p>
              </span>
            </InfoContainer>

            {/* {able && at.type === 'O' && (
        <PrintButton
          icon={FaFileExcel}
          linkTo={`/sgo/xls_oficina_presenca.php?data=${JSON.stringify({
            seq: at.seq,
          })}`}
          hasProps
        />
            )} */}
            {able && (
              <PrintContainer>
                <button onClick={handlePrint} type="button">
                  <FaFileExcel />
                  Gerar Lista de Presença
                </button>
                <a
                  id="xls"
                  rel="noreferrer noopener"
                  target="_top"
                  href={`${
                    process.env.REACT_APP_API
                  }/sgo/xls_oficina_presenca.php?data=${JSON.stringify({
                    seq: at.seq,
                  })}`}
                >
                  XLS
                </a>
              </PrintContainer>
            )}

            <InfoMessageContainer>
              <div style={{ fontStyle: 'italic', textDecoration: 'underline' }}>
                <strong>Orientações sobre as marcações:</strong>
              </div>
              <div>
                <div>
                  <span style={{ fontStyle: 'italic' }}>
                    <strong>Medição:</strong>
                  </span>
                  &nbsp;Oficinistas de TOV Adultos que viveram a 1ª sessão:
                  &ldquo;Deus da Ternura&rdquo; mais os novos que chegaram na 2ª
                  sessão: &ldquo;Se conhecessem o Pai.&rdquo;
                </div>
                <div>
                  <span style={{ fontStyle: 'italic' }}>
                    <strong>Perseverantes:</strong>
                  </span>
                  &nbsp;Oficinistas de TOV Adultos que viveram o Deserto e que
                  participaram da primeira sessão mais os que se juntaram à
                  segunda sessão.
                </div>
              </div>
            </InfoMessageContainer>
            <GridContainer>
              {list.map((item: OficinistasProps) => (
                <GridItem key={item.oseq}>
                  <p>{item.name}</p>
                  <TelephoneContainer>
                    {item.fcel && (
                      <p>
                        Celular:{' '}
                        <a href={`tel:${item.fcel.replace(/\D/g, '')}`}>
                          {item.fcel}
                        </a>
                      </p>
                    )}
                    {item.fres && (
                      <p>
                        Telefone:{' '}
                        <a href={`tel:${item.fres.replace(/\D/g, '')}`}>
                          {item.fres}
                        </a>
                      </p>
                    )}
                  </TelephoneContainer>

                  {item.mail && (
                    <span>
                      <p>
                        Email:{' '}
                        <a
                          href={`mailto:${item.mail}?subject=[TOV BRASIL] - Mensagem para o Oficinista`}
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          {item.mail}
                        </a>
                      </p>
                    </span>
                  )}

                  <span>
                    <Checkbox
                      id={`M${item.oseq}`}
                      title="Sessão Medição"
                      checked={item.sessmed}
                      onClick={() => handleCheckbox('M', item.oseq)}
                      isDisabled={
                        !allowSession || JSON.stringify(item.pers) === 'true'
                      }
                    />
                    <Checkbox
                      id={`P${item.oseq}`}
                      title="Perseverante"
                      checked={item.pers}
                      onClick={() => handleCheckbox('P', item.oseq)}
                      isDisabled={
                        !allowPersistent ||
                        JSON.stringify(item.sessmed) === 'false'
                      }
                      // isDisabled={
                      //   (!able ||
                      //     isAfter(
                      //       new Date(parseISO(at.dtfim)),
                      //       new Date(new Date().toISOString()),
                      //     )) &&
                      //   !item.sessmed
                      // }
                    />
                  </span>
                  <O.GridOptions>
                    <O.Show
                      onClick={() =>
                        setShow({
                          title: deconvertSpecialChars(item.name),
                          open: true,
                          content: (
                            <ModalShowContent>
                              <span>
                                {item.fcel && (
                                  <p>
                                    Celular:{' '}
                                    <a
                                      href={`tel:${item.fcel.replace(
                                        /\D/g,
                                        '',
                                      )}`}
                                    >
                                      {item.fcel}
                                    </a>
                                  </p>
                                )}

                                {item.fres && (
                                  <p>
                                    Telefone:{' '}
                                    <a
                                      href={`tel:${item.fres.replace(
                                        /\D/g,
                                        '',
                                      )}`}
                                    >
                                      {item.fres}
                                    </a>
                                  </p>
                                )}
                              </span>

                              {item.mail && (
                                <span>
                                  <p>
                                    Email:{' '}
                                    <a
                                      href={`mailto:${item.mail}?subject=[TOV BRASIL] - Mensagem para o Oficinista`}
                                      rel="noopener noreferrer"
                                      target="_blank"
                                    >
                                      {item.mail}
                                    </a>
                                  </p>
                                </span>
                              )}

                              {item.cep && (
                                <span>
                                  <p>
                                    CEP: <strong>{item.cep}</strong>
                                  </p>
                                </span>
                              )}
                              {item.address && (
                                <span>
                                  <p>
                                    Endereço:{' '}
                                    <strong>
                                      {deconvertSpecialChars(item.address)}
                                    </strong>
                                  </p>
                                </span>
                              )}

                              {item.district && (
                                <span>
                                  <p>
                                    Bairro:{' '}
                                    <strong>
                                      {deconvertSpecialChars(item.district)}
                                    </strong>
                                  </p>
                                </span>
                              )}

                              {item.city && (
                                <span>
                                  <p>
                                    Cidade:{' '}
                                    <strong>
                                      {deconvertSpecialChars(item.city)}&nbsp;
                                      {item.state && `| ${item.state}`}
                                    </strong>
                                  </p>
                                </span>
                              )}

                              {item.dtnasc && item.dtnasc !== '0000-00-00' && (
                                <span>
                                  <p>
                                    Data de{' '}
                                    {at.tscod === 'CC'
                                      ? 'Matrimônio'
                                      : 'Nascimento'}
                                    :{' '}
                                    <strong>
                                      {format(
                                        new Date(parseISO(item.dtnasc)),
                                        'dd/MM/yyyy',
                                      )}
                                    </strong>
                                  </p>
                                </span>
                              )}
                            </ModalShowContent>
                          ),
                        })
                      }
                    >
                      <FaSearch />
                    </O.Show>
                    {able && (
                      <O.Update
                        to={{
                          pathname: `${pathname}/update`,
                          state: { ofic: item, type: at.type, tscod: at.tscod },
                        }}
                      >
                        <FaPencilAlt />
                      </O.Update>
                    )}
                    {able &&
                      isAfter(
                        addDays(
                          new Date(parseISO(item.dtcad).toUTCString()),
                          10,
                        ),
                        new Date(new Date().toUTCString()),
                      ) &&
                      !item.sessmed &&
                      !item.pers && (
                        <O.Delete
                          onClick={() =>
                            setDeleteDiag({
                              open: true,
                              content: (
                                <ModalDeleteContent>
                                  <p>
                                    Você está prestes a excluir{' '}
                                    <strong>permanentemente</strong> o registro
                                    do oficinista{' '}
                                    <strong>
                                      {deconvertSpecialChars(item.name)}.
                                    </strong>
                                  </p>
                                  <p>
                                    Se estiver seguro de sua decisão, clique em
                                    confirmar.
                                  </p>
                                </ModalDeleteContent>
                              ),
                              values: {
                                type: at.type,
                                name: deconvertSpecialChars(item.name),
                                oseq: item.oseq,
                                seq: at.seq,
                              },
                            })
                          }
                        >
                          <FaTimes />
                        </O.Delete>
                      )}
                  </O.GridOptions>
                </GridItem>
              ))}
            </GridContainer>
          </>
        ) : (
          <>
            <InfoContainer>
              <span>
                <p>
                  Total de Guias Cadastrados:&nbsp;
                  <strong>{list.length}</strong>
                </p>
              </span>
            </InfoContainer>
            <GridContainer>
              {list.map((item: OficinistasProps) => (
                <GridSVCItem>
                  <p>{item.name}</p>
                  <span>
                    <p>
                      Local:
                      <strong>{item.locdesc}</strong>
                    </p>
                  </span>
                  <span>
                    <p>
                      <strong>{item.anbdesc}</strong>
                    </p>
                  </span>
                  <span>
                    <p>
                      <strong>{item.zondesc}</strong>
                    </p>
                  </span>
                  {able && (
                    <O.GridOptions>
                      <O.Delete
                        onClick={() =>
                          setDeleteDiag({
                            open: true,
                            content: (
                              <ModalDeleteContent>
                                <p>
                                  Você está prestes a excluir{' '}
                                  <strong>permanentemente</strong> o registro do
                                  participante{' '}
                                  <strong>
                                    {deconvertSpecialChars(item.name)}.
                                  </strong>
                                </p>
                                <p>
                                  Se estiver seguro de sua decisão, clique em
                                  confirmar.
                                </p>
                              </ModalDeleteContent>
                            ),
                            values: {
                              type: at.type,
                              name: deconvertSpecialChars(item.name),
                              oseq: item.egseq,
                              seq: at.seq,
                            },
                          })
                        }
                      >
                        <FaTimes />
                      </O.Delete>
                    </O.GridOptions>
                  )}
                </GridSVCItem>
              ))}
            </GridContainer>
          </>
        )}

        <S.Container scroll="paper" maxWidth={false} open={sPrint.open}>
          <S.Title>
            <h2>Gerando Arquivo</h2>
          </S.Title>
          <S.Content>
            <div>
              <p>O arquivo já está sendo gerado!</p>
              <p>Este aviso pode ser fechado e você pode voltar a navegação.</p>
              <p>
                Assim que estiver pronto o download começará automaticamente.
              </p>
            </div>
          </S.Content>
          <S.Actions>
            <S.Confirm type="button" onClick={handlePrintDiag}>
              OK
            </S.Confirm>
          </S.Actions>
        </S.Container>

        <S.Container scroll="paper" maxWidth={false} open={show.open}>
          <S.Title>
            <h2>{show.title || 'Consulta'}</h2>
          </S.Title>
          <S.Content>{show.content}</S.Content>
          <S.Actions>
            <S.Confirm
              type="button"
              onClick={() =>
                setShow({
                  ...show,
                  open: false,
                })
              }
            >
              OK
            </S.Confirm>
          </S.Actions>
        </S.Container>
        <D.Container scroll="paper" maxWidth={false} open={deleteDiag.open}>
          <D.Title>
            <h2>*** ATENÇÃO ***</h2>
          </D.Title>
          <D.Content>{deleteDiag.content}</D.Content>
          <D.Actions>
            <D.Cancel
              type="button"
              onClick={() => setDeleteDiag({ open: false })}
            >
              Cancelar
            </D.Cancel>
            <D.Confirm
              type="button"
              onClick={() => handleExclusion(deleteDiag.values)}
            >
              Confirmar
            </D.Confirm>
          </D.Actions>
        </D.Container>
      </Content>
      <SGOFooter />
    </Container>
  );
};

export default AtividadesParticipantes;
