import React, { useCallback, useEffect, useState } from 'react';

import {
  Container,
  AlteredContent,
  Header,
  AlteredHeader,
} from 'styles/sgo_wrappers';
import { Link, useLocation } from 'react-router-dom';

import SGOHeader from 'components/SGOHeader';
import SGONavbar from 'components/SGONavbar';
import ScrollTop from 'components/ScrollTop';
import SGOFooter from 'components/SGOFooter';

import * as S from 'styles/dialog_consult';
import { ShowProps } from 'styles/dialog_consult';

import { FaRegFileExcel, FaRegListAlt, FaLock } from 'react-icons/fa';

import { useAuth } from 'hooks/auth';
import { useToast } from 'hooks/toast';
import { useCredentials } from 'hooks/credentials';

import { useSpring } from 'react-spring';
import api from 'services/api';
import Loading from 'components/Loading';
import {
  Menu,
  AnimatedDiv,
  ButtonsContainer,
  ButtonProceed,
  ButtonCancel,
} from './styles';
import { PreINC } from '../insert/styles';

const PedidoMenu: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const { user } = useAuth();
  const { pathname } = useLocation();
  const { checkCredentials, handlePermission } = useCredentials();
  const [show, setShow] = useState<ShowProps>({} as ShowProps);
  const [mode, setMode] = useState<string>('menu');
  const [accept, setAccept] = useState('');
  const { addToast } = useToast();

  useEffect(() => {
    handlePermission(['ZON'], true);
    checkCredentials();
  }, [checkCredentials, handlePermission]);

  const showDialog = useCallback(() => {
    setShow((state) => ({ ...state, open: true }));
    const timer = setTimeout(() => {
      setShow((state) => ({
        ...state,
        open: false,
      }));
    }, 5000);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  const handleDownload = useCallback(
    (ev) => {
      showDialog();
      const { id } = ev.currentTarget.dataset;
      const link = document.querySelector<HTMLAnchorElement>(`a[id=${id}]`);

      if (link) {
        link.click();
      }
    },
    [showDialog],
  );

  const cancelBlockMode = useCallback(() => {
    setMode('menu');
    setAccept('');
  }, []);
  const handleBlockConfirmation = useCallback(() => {
    setMode('block_confirm');
  }, []);

  const handleLock = useCallback(async () => {
    try {
      setLoading(true);
      const send = new FormData();
      send.append(
        'data',
        JSON.stringify({
          mode: 'blockAll',
        }),
      );
      await api.post('/sgo/pedido_lock_management.php', send, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      cancelBlockMode();
      addToast({
        type: 'success',
        title: 'Sucesso',
        description: 'Pedidos bloqueados!',
      });
    } catch (e) {
      //
    } finally {
      setLoading(false);
    }
  }, [addToast, cancelBlockMode]);

  const handlePreIncInput = useCallback(
    (e: React.FormEvent<HTMLInputElement>) => {
      setAccept(e.currentTarget.value.toLowerCase());

      return e.currentTarget.value;
    },
    [],
  );

  const styledProceedPreInc = useSpring({
    transform:
      accept === 'bloquear' && mode === 'block_confirm'
        ? 'translateX(0px)'
        : 'translateX(-60px)',
    opacity: accept === 'bloquear' && mode === 'block_confirm' ? 1 : 0,
    pointerEvents:
      accept === 'bloquear' && mode === 'block_confirm' ? 'all' : 'none',
  });

  return (
    <Container>
      <Loading isLoading={loading} />
      <ScrollTop />
      <SGOHeader />
      <SGONavbar />
      <Header>Menu de Pedidos de Material</Header>
      <AlteredContent pixels="231px">
        {mode === 'menu' ? (
          <>
            <Menu>
              {/* <AnimatedDiv duration={1.1}>
                <Link to={`${pathname.replace('menu', 'estoque')}`}>
                  <FaRegListAlt size={30} />
                  <h3>Estoque ANB x Pedido Colocado</h3>
                </Link>
              </AnimatedDiv> */}

              <AnimatedDiv duration={1.1} delay={0.35}>
                <Link to={`${pathname.replace('/menu', '')}`}>
                  <FaRegListAlt size={30} />
                  <h3>Relação dos Pedidos</h3>
                </Link>
              </AnimatedDiv>
              <AnimatedDiv duration={1.1} delay={0.7}>
                <Link to={`${pathname.replace('menu', 'fornecedor')}`}>
                  <FaRegListAlt size={30} />
                  <h3>Agrupamento por Fornecedor</h3>
                </Link>
              </AnimatedDiv>
              {user.perfil === 'ZON' ? (
                <>
                  <AnimatedDiv duration={1.1} delay={1.05}>
                    <button
                      type="button"
                      data-id="blockPedidos"
                      onClick={handleBlockConfirmation}
                    >
                      <FaLock size={30} />
                      <h3>Bloqueio dos Pedidos em Aberto</h3>
                    </button>
                  </AnimatedDiv>
                  <AnimatedDiv duration={1.1} delay={1.4}>
                    <button
                      type="button"
                      data-id="supplierXLS"
                      onClick={handleDownload}
                    >
                      <FaRegFileExcel size={30} />
                      <h3>Lista por Fornecedor</h3>
                    </button>
                    {/* <Link to={`${pathname.replace('menu', 'fornecedor')}`}>
              <FaRegFileExcel size={30} />
              <h3>Lista por Fornecedor</h3>
            </Link> */}
                  </AnimatedDiv>
                </>
              ) : null}
            </Menu>

            <S.Container scroll="paper" maxWidth={false} open={show.open}>
              <S.Title>
                <h2>{show.title || 'Consulta'}</h2>
              </S.Title>
              <S.Content>
                <div>
                  <p>O arquivo já está sendo gerado!</p>
                  <p>
                    Assim que estiver pronto o download começará
                    automaticamente.
                  </p>
                  <p>Aguarde...</p>
                </div>
              </S.Content>
              <S.Actions>
                <S.Confirm
                  type="button"
                  onClick={() => setShow({ ...show, open: false })}
                >
                  Ok
                </S.Confirm>
              </S.Actions>
            </S.Container>

            {user.perfil === 'ZON' ? (
              <a
                style={{ display: 'none' }}
                id="supplierXLS"
                href={`${
                  process.env.REACT_APP_API
                }/sgo/xls_agrupamento_fornecedor.php?ZONAL=${user.zoncod.substr(
                  0,
                  2,
                )}`}
                rel="noreferrer noopener"
                target="_top"
              >
                Lista por Fornecedor
              </a>
            ) : null}
          </>
        ) : (
          <PreINC>
            <AlteredHeader>Atenção</AlteredHeader>
            <p>
              Após solicitar o bloqueio, todos os pedidos das Nacionais de sua
              Coordenação Zonal que estão em aberto serão marcados como
              bloqueados para alterações ou exclusões e só poderão ser
              desbloqueados pelos guias da coordenação zonal.
            </p>

            <p>
              Para prosseguir, digite a palavra <strong>BLOQUEAR</strong> na
              caixa abaixo e pressione o botão &quot;Prosseguir&quot;
            </p>
            <span>
              <input
                type="text"
                min={0}
                onChange={(e: React.FormEvent<HTMLInputElement>) =>
                  handlePreIncInput(e)
                }
                onBlur={(e: React.FormEvent<HTMLInputElement>) =>
                  handlePreIncInput(e)
                }
              />
            </span>
            <ButtonsContainer>
              <ButtonCancel type="button" onClick={cancelBlockMode}>
                Cancelar
              </ButtonCancel>
              <ButtonProceed
                type="button"
                style={styledProceedPreInc}
                onClick={handleLock}
              >
                Prosseguir
              </ButtonProceed>
            </ButtonsContainer>
            {/* <ProceedButton style={styledProceedPreInc}>
              Prosseguir
            </ProceedButton> */}
          </PreINC>
        )}
      </AlteredContent>
      <SGOFooter />
    </Container>
  );
};

export default PedidoMenu;
